<template>
    <v-dialog width="800" max-width="100vw" v-model="showDialogAdd">
        <template v-slot:activator="{ on, attrs }">
            <v-btn fab icon v-bind="attrs" v-on="on">
                <v-icon> mdi-plus-circle-outline </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>Peer toevoegen</v-card-title>
            <v-card-text>
                <v-text-field v-model="peer.firstName" label="Voornaam"></v-text-field>
                <v-text-field v-model="peer.lastName" label="Achternaam"></v-text-field>
                <v-text-field v-model="peer.email" label="Email"></v-text-field>
                <v-text-field v-model="peer.relationType" label="Relatie"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn plain v-show="canAdd" :loading="loading" @click.once="addPeer">Bevestigen</v-btn>
                <v-spacer />
                <v-btn plain @click="showDialogAdd = false">Annuleren</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { db } from "@/services/firebase";
import { collection, setDoc, doc, addDoc } from "firebase/firestore";
import { mapGetters } from "vuex";
export default {
    name: "addPeer",
    props: {
        surveyId: String
    },
    data() {
        return {
            loading: false,
            peer: {
                firstName: "",
                lastName: "",
                email: "",
                relationType: "",
            },
            showDialogAdd: false
        };
    },
    watch: {
        showDialogAdd: function (o, n) {
            if (o != n) {
                this.peer.firstName = ""
                this.peer.lastName = ""
                this.peer.email = ""
                this.peer.relationType = ""
            }
        }
    },
    computed: {
        ...mapGetters({
            userInfo: "session/userInfo",
        }),
        canAdd() {
            const emailPattern = /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
            return (this.peer.firstName && this.peer.lastName && emailPattern.test(this.peer.email) == true)
        },
    },
    methods: {

        async addPeer() {
            this.loading = true;
            this.peer.email = this.peer.email.trim();
            try {
                const docRef = doc(collection(db, "surveys", this.surveyId, "peers"))
                await setDoc(docRef, {
                    firstName: this.peer.firstName,
                    lastName: this.peer.lastName,
                    email: this.peer.email,
                    relationType: this.peer.relationType
                }, { merge: true });
                this.$emit("refreshList");
                this.showDialogAdd = false
            } catch (err) {
                this.$store.dispatch("session/showBottomMessage", err);
            }
            this.loading = false;
        },
    },
    created() {
    },
};
</script>
