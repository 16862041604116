<template>
    <v-container fluid class="pa-0">
        <v-toolbar elevation="1">
            <v-toolbar-title>Peers</v-toolbar-title>
            <v-spacer />
            <!-- ADD PEERS -->
            <AddPeer v-on:refreshList="fetchPeers()" :surveyId="surveyId" />
            <v-spacer />
            <v-icon @click="$router.go(-1)">mdi-close-circle</v-icon>
        </v-toolbar>
        <v-container>
            <v-data-table :items="peers" :headers="headers" :disable-sort="this.$vuetify.breakpoint.mobile"
                sortBy="sortNr" :loading="loading" disable-pagination hide-default-footer no-data-text=""
                no-results-text="">
                <template v-slot:[`item.sortNr`]="{ item }">
                    {{ item.sortNr + 1 }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn v-show="showSendInvitation(item)" plain @click="sendInvitation(item)"
                        :loading="loading">Uitnodigen</v-btn>
                    <v-btn v-show="showOpenPeerReview(item)" plain @click="openPeerReview(item)"
                        :loading="loading"><v-icon>mdi-chevron-right</v-icon></v-btn>
                    <v-btn plain @click="deletePeerReview(item)" :loading="loading" disabled><v-icon
                            small>mdi-delete</v-icon></v-btn>
                </template>
            </v-data-table>
        </v-container>
    </v-container>
</template>
<script>
import AddPeer from "@/components/AddPeer.vue"
import { db, functions } from "@/services/firebase";
import { httpsCallable } from "firebase/functions";
import { collection, doc, getDoc, getDocs, deleteDoc } from "firebase/firestore";
import { mapGetters } from "vuex";
import { onSnapshot } from "firebase/firestore";
export default {
    name: "Coachees",
    components: { AddPeer },
    data() {
        return {
            loading: false,
            coachee: {},
            peers: [],
            tableHeight: null,
            dialogKey: 1,
            searchText: "",
            showDialogAdd: false,
            showDialogUpdate: false,
            surveyId: '',
        };
    },
    created() {
        this.$watch(
            () => this.$route.params,
            (_new, _old) => {
                if (
                    _new &&
                    _new.id &&
                    _new != _old &&
                    this.$route.name === "Peers"
                ) {
                    this.surveyId = _new.id;
                    this.fetchPeers()
                    this.fetchCoachee()
                }
            },
            // fetch the data when the view is created and the data is
            // already being observed
            { immediate: true }
        );
    },
    computed: {
        ...mapGetters({
            userInfo: "session/userInfo",
        }),
        headers() {
            return [
                { text: "Volgnr", value: "sortNr" },
                { text: "Naam", value: "lastName" },
                { text: "Voornaam", value: "firstName" },
                { text: "Email", value: "email" },
                { text: "Relatie type", value: "relationType" },
                { text: "Acties", value: "actions" },
            ];
        },
    },

    beforeDestroy() {
        if (this.unsubscribePeers) {
            this.unsubscribePeers();
        }
    },


    methods: {

        async deletePeerReview(item) {
            this.loading = true
            const documentRef = doc(db, "surveys", this.surveyId, "peers", item.id);
            await deleteDoc(documentRef)
            await this.fetchPeers()
            this.loading = false

        },

        showSendInvitation(item) {
            return /*this.userInfo.role == 'coachee' &&*/ !item.emailSent
        },

        showOpenPeerReview(item) {
            return this.userInfo.role == 'coach' || this.userInfo.role == 'admin'
        },

        // 
        async fetchCoachee() {
            const cRef = doc(db, "surveys", this.surveyId);
            const res = await getDoc(cRef);
            this.coachee = res.data().coachee;
        },

        // refactor this function to a real-time listener
        async fetchPeers() {
            const cRef = collection(db, "surveys", this.surveyId, "peers");
            const unsubscribe = onSnapshot(cRef, (snapshot) => {
                this.peers = snapshot.docs.map((doc) => {
                    return { ...doc.data(), id: doc.id };
                });
            });
            // Store the unsubscribe function for later cleanup
            this.unsubscribePeers = unsubscribe;
        },

        getLink(peer) {
            const url = "https://gedragsvoorkeuren.fulfil.be/peer-review?link="
            const link = "/surveys/" + this.surveyId + '/peers/' + peer.id
            return (url + link)
        },

        async sendInvitation(peer) {
            this.loading = true;
            try {
                const params = {
                    peer,
                    coachee: this.coachee,
                    link: this.getLink(peer),
                    surveyRef: "/surveys/" + this.surveyId + '/peers/' + peer.id
                };
                const call = httpsCallable(functions, "sendEmailToPeer");
                await call(params);
                await this.fetchPeers()
                this.loading = false;
            } catch (e) {
                console.error(e);
                this.loading = false;
            }
        },

        openPeerReview(peer) {
            this.$router.push({ name: 'PeerReviewInternal', params: { surveyId: this.surveyId, peerId: peer.id } });
        }
    }
}

</script>